import { useEffect, useMemo, useRef } from 'react'

const debounce = (callback, interval) => {
  let timeoutId = null;

  return (...args) => {
    window.clearTimeout(timeoutId)

    timeoutId = window.setTimeout(() => {
      callback(...args)
    }, interval)
  }
}

// A custom hook to debounce functions
export function useDebounce (callback, timeout) {
  const callbackRef = useRef(callback)

  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  const debouncedCallback = useMemo(() => {

    return debounce(callbackRef.current, timeout)
  }, [])

  return debouncedCallback
}
