import React, { useState, useMemo } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel
} from '@material-ui/core'


/**
 * @typedef TableColumns
 * @property {string} Header
 * @property {string} accessor
 */
/**
 * @typedef MultiSortData
 * @property {string} id
 * @property {boolean} desc
 */
/**
 * A basic mui table w/ basic column sorting capabilities
 * @param {object} props
 * @param {TableColumns[]} props.columns
 * @param {object[]} props.data
 * @param {object[]} props.defaultSorted
 */
const SortableBasicTable = ({ columns, data, defaultSorted }) => {

  const [sortField, setSortField] = useState('')
  const [sortCol, setSortCol] = useState('')
  const [desc, setDesc] = useState(false)
  /** @type {[MultiSortData[], React.Dispatch<MultiSortData[]>]}*/
  const [multiSortCols, setMultiSortCols] = useState(defaultSorted || [])


  const sortSingle = () => {
    let dataClone = [...data]
    return dataClone.sort((a, b) => {
      if (a[sortField] == b[sortField]) {
        return 0
      } else {
        let compare = a[sortField] > b[sortField] ? 1 : -1
        return desc ? -compare : compare
      }
    })
  }

  const sortMultiple = () => {
    let dataClone = [...data]
    return dataClone.sort((a, b) => {
      for(let i = 0; i < multiSortCols.length; i++ ) {
        const col = multiSortCols[i].id
        const desc = multiSortCols[i].desc
        let isLastColumn = i == multiSortCols.length - 1
        if (a[col] == b[col]) {
          if (isLastColumn)
            return 0
          continue
        } else {
          let compare = a[col] > b[col] ? 1 : -1
          return desc ? -compare : compare
        }
      }
    })
  }

  const sortedData = useMemo(() => {
    return multiSortCols.length ? sortMultiple() : sortSingle()
  }, [columns, sortField, desc, multiSortCols])


  return (
    <Table>
      <TableHead>
        <TableRow key='table-header'>
          {columns.map((column) => {
            let isDesc = desc
            let isActive = (sortCol == column.accessor)
            let multiSortCol
            if (multiSortCols.length > 0) {
              multiSortCol = multiSortCols.find((col) => {
                return col.id == column.accessor
              })
              if (multiSortCol) {
                isDesc = multiSortCol.desc
                isActive = true
              }
            }
            return (
              <TableCell key={column.accessor}>
                <TableSortLabel
                  direction={isDesc ? 'desc' : 'asc'}
                  active={isActive}
                  onClick={(event) => {
                    if (event.shiftKey) {
                      /* setMultiSort(true) */
                      const newMultiSortCol = {
                        id: column.accessor,
                        desc: false
                      }
                      if (multiSortCols.length > 0) {
                        const existingCol = multiSortCols.find((col) => {
                          return col.id == column.accessor
                        })
                        if (existingCol) {
                          existingCol.desc = !existingCol.desc
                          setMultiSortCols([...multiSortCols])
                        } else
                          setMultiSortCols([...multiSortCols , newMultiSortCol])
                      } else {
                        setMultiSortCols([
                          { id: sortCol, desc: desc }, newMultiSortCol])
                      }
                    } else {
                      setMultiSortCols([])
                      setDesc(!desc)
                      setSortField(column.accessor)
                      setSortCol(column.accessor)
                    }
                  }}
                >{column.Header}</TableSortLabel>
              </TableCell>
            )
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {sortedData.map((row, i) => {
          return (
            <TableRow key={i}>
              {columns.map((column, j) => {
                return (<TableCell key={j}>{row[column.accessor]}</TableCell>)
              })}
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

export default SortableBasicTable
