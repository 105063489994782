import React from 'react'
import { Grid, TextField, Typography } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import SortableBasicTable from './SortableBasicTable'
import '../types'

/**
 * @param {object} params
 * @param {Brand[]} params.brands
 * @param {string} params.currentBrand
 * @param {BrandLocation[]} params.brandLocations
 * @param {Function} params.onChangeBrand
 */
const BrandLocationTable = (
  { brands, currentBrand, brandLocations, onChangeBrand}
) => {

  const columns = [
    {
      Header: 'Location',
      accessor: 'location'
    },
    {
      Header: 'On Hand',
      accessor: 'onHand'
    }
  ]

  return (
    <>
      <Typography
        style={{ marginInline: 15, marginTop: 5 }}
        variant='subtitle1'>
        Select a brand to see all main warehouse locations containing products from that brand
      </Typography>
      <Grid container spacing={2} style={{ padding: 15 }}>
        <Grid item xs={12} sm={4}>
          <Autocomplete
            value={currentBrand}
            onChange={onChangeBrand}
            options={brands}
            getOptionLabel={(option) => option.name || ''}
            renderInput={(params) => (
              <TextField {...params} label='Brand' variant='outlined' />
            )}
            onKeyDown={(event) => event.stopPropagation()}
          />
        </Grid>
      </Grid>
      {(brandLocations.length > 0) && (
        <SortableBasicTable
          data={brandLocations}
          columns={columns}
          defaultSorted={[{ id: 'onHand', desc: true }]}
        />
      )}
    </>
  )
}

export default BrandLocationTable
