import React, { useState, useEffect } from 'react'
import Autocomplete, {
  createFilterOptions
} from '@material-ui/lab/Autocomplete'
import { TextField } from '@material-ui/core'
import axios from 'axios'
import '../types'


const LocationAutocomplete = (props) => {

  const {
    options: propOptions,value: propValue, defaultValue, onChange,
    tabIndex, ...propsRest
  } = props

  const isControlled = typeof propValue != 'undefined'
  const hasOptions = typeof propOptions != 'undefined'
  const hasDefaultValue = typeof defaultValue != 'undefined'
  const initialValue = hasDefaultValue ? defaultValue : ''

  /** @type {[Destination[], React.Dispatch<Destination[]>]} */
  const [locations, setLocations] = useState([])
  /** @type {[Destination, React.Dispatch<Destination>]} */
  const [internalValue, setInternalValue] = useState(initialValue)

  const value = isControlled ? propValue : internalValue
  const options = hasOptions ? propOptions : locations

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    ignoreCase: true,
    stringify: option => {
      const location = option.location
      const noDashes = location.replaceAll('-', '')
      const noZeros = noDashes.replaceAll('0', '')
      return `${location} ${noDashes} ${noZeros}`
    }
  })

  useEffect(() => {
    if (!hasOptions) {
      axios.get('/api/get-all-locations').then((res) => {
        setLocations(res.data)
      })
    }
  }, [])

  return (
    <Autocomplete
      value={value || null}
      onChange={(event, newValue, reason) => {
        if (onChange) {
          onChange(event, newValue, reason)
        }
        if (!isControlled) {
          if (reason == 'clear')
            setInternalValue('')
          else if (newValue) {
            setInternalValue(newValue)
          }
        }
      }}
      options={options}
      filterOptions={filterOptions}
      getOptionLabel={(option) => option.location || ''}
      getOptionSelected={
        (option, value) => option.location === value.location
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label='Location' variant='outlined'
          inputProps={{ ...params.inputProps, tabIndex: (tabIndex || 0) }}
        />
      )}
      {...propsRest}
    />
  )
}

export default LocationAutocomplete
