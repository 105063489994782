import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Grid from '@material-ui/core/Grid'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import ExitIcon from '@material-ui/icons/ExitToApp'
import MenuIcon from '@material-ui/icons/Menu'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ExternalLink from './ExternalLink'

const buttons = [
  {
    label: 'Reporting',
    to: {
      Dashboard: '/reporting',
      'Job Monitor': '/job-monitor',
    },
    authLevel: 'admin',
  },
  {
    label: 'Used',
    to: {
      Intake: '/used',
      Inventory: '/used-inventory-report',
      'New SKUs': '/used-new-sku',
    },
    authLevel: 'confirmed',
  },
  {
    label: 'Sales',
    to: {
      Overstocks: '/sale-calc',
      Purchasing: '/purchasing',
      Backorders: '/backorders',

      Demos: '/demo',
      'Top Sellers': '/top-sellers',
      'Price Guide': '/price-guide',
      Margins: '/margin-report',
    },
    authLevel: 'confirmed',
  },
  {
    label: 'Product',
    to: {
      Audit: '/audit',
      Brands: '/brands',
      Reverb: '/reverb',
      eBay: '/ebay',
      Related: '/related',
      Configurable: '/configurable',
      Images: '/images',
      Text: '/text',
    },
    authLevel: 'confirmed',
  },
  {
    label: 'Warehouse',
    to: {
      Receiving: '/receiving',
      Transfer: '/transfer',
      Location: '/location',
      'Stock Corrections': '/stock-corrections',
      Preorders: '/preorders',
      Warranty: '/warranty',
    },
    authLevel: 'confirmed',
  },
]

const Header = ({ auth }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 875)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [menuAnchor, setMenuAnchor] = useState(null)
  const [activeMenu, setActiveMenu] = useState(null)

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 875)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open)
  }

  const handleMenuOpen = (event, index) => {
    setMenuAnchor(event.currentTarget)
    setActiveMenu(index)
  }

  const handleMenuClose = () => {
    setMenuAnchor(null)
    setActiveMenu(null)
  }

  const renderDrawerContent = () => (
    <List>
      {buttons.map((button, index) => {
        if (auth && auth[button.authLevel]) {
          return (
            <span key={index}>
              <ListItem>
                <Typography variant='h6'>{button.label}</Typography>
              </ListItem>
              {Object.entries(button.to).map(([label, route]) => (
                <ListItem
                  onClick={toggleDrawer(false)}
                  key={label}
                  button
                  component={Link}
                  to={route}>
                  {label}
                </ListItem>
              ))}
            </span>
          )
        }
      })}
      <ListItem>
        <Button href='/api/logout'>Logout</Button>
      </ListItem>
    </List>
  )

  return (
    <AppBar>
      <Toolbar
        style={{
          minHeight: 44,
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        {auth && auth.confirmed ? (
          <Grid container alignItems='center'>
            <Button component={Link} to={'/'}>
              <img
                style={{ height: 20 }}
                src={
                  'https://www.perfectcircuit.com/media/wysiwyg/reactor_icon.png'
                }
                alt='reactLogo'
              />
            </Button>
            {auth && (
              <>
                <ExternalLink
                  href='https://sites.google.com/perfectcircuitaudio.com/pcawiki/'
                  target='_blank'
                  content={
                    <Button>
                      Wiki <ExitIcon style={{ marginLeft: 10 }} />
                    </Button>
                  }
                />
                <ExternalLink
                  href='https://chrome.google.com/webstore/detail/perfect-circuit/paahmdhmapfcbkineialohmhhlonfjad'
                  target='_blank'
                  content={
                    <Button>
                      Chrome Ext <ExitIcon style={{ marginLeft: 10 }} />
                    </Button>
                  }
                />
              </>
            )}
          </Grid>
        ) : null}

        {isMobile ? (
          <IconButton edge='start' color='inherit' onClick={toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
        ) : (
          <Grid container wrap='nowrap' spacing={1} justifyContent='flex-end'>
            {buttons.map((button, index) => {
              if (auth && auth[button.authLevel]) {
                return (
                  <Grid item key={index}>
                    <Button
                      color='default'
                      onClick={(e) => handleMenuOpen(e, index)}>
                      {button.label}
                    </Button>
                    <Menu
                      anchorEl={menuAnchor}
                      open={Boolean(menuAnchor) && activeMenu === index}
                      onClose={handleMenuClose}
                      style={{ marginTop: 20 }}>
                      {Object.entries(button.to).map(([label, route]) => (
                        <MenuItem
                          key={label}
                          component={Link}
                          to={route}
                          onClick={handleMenuClose}>
                          {label}
                        </MenuItem>
                      ))}
                    </Menu>
                  </Grid>
                )
              }
            })}
            <Grid item>
              {auth ? (
                <Button href='/api/logout'>Logout</Button>
              ) : (
                <Button
                  variant='contained'
                  color='secondary'
                  href='/auth/google'>
                  Login
                </Button>
              )}
            </Grid>
          </Grid>
        )}
        <Drawer anchor='left' open={drawerOpen} onClose={toggleDrawer(false)}>
          <div style={{ width: 250, padding: 20 }}>{renderDrawerContent()}</div>
        </Drawer>
      </Toolbar>
    </AppBar>
  )
}

const mapStateToProps = (state) => ({
  auth: state.auth,
})

export default connect(mapStateToProps)(Header)
